<template>
  <div class="news-box">
    <Header :category-list="categoryList" :from="name" />
    <iframe class="nesting-iframe" id="iframe2" frameborder="0"
      src="https://xsj.yuwell.com/jz/showIndex?doorId=18&noFunc=1" />
    <div style="width: 100%;height: 30px;background-color: #f7f9fa;position: absolute;bottom: 0;"></div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
  // @ is an alias to /src
  import Header from '@/components/Header.vue'
  // import Footer from '@/components/Footer.vue'
  import {
    getFirstAndSecondCategoryUrl
  } from '@/api/common'

  export default {
    name: 'News',
    components: {
      Header
      // Footer
    },
    data() {
      return {
        name: 'News',
        categoryList: []
      }
    },
    mounted() {
      this.getCategory()
      const ifm = document.getElementById('iframe2')
      ifm.height = document.documentElement.clientHeight - 72
      console.log(ifm.height, 'ifm.height')
    },
    methods: {
      getCategory() {
        getFirstAndSecondCategoryUrl().then(res => {
          this.categoryList = res.data.datas
        })
      }
    }
  }
</script>
<style scoped lang="less">
  .news-box {
    position: relative;
  }

  .nesting-iframe {
    position: absolute;
    top: 72px;
    width: 100%;
  }
</style>
